import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AdminComponent} from './layout/admin/admin.component';
import {AuthComponent} from './layout/auth/auth.component';
import { LogoutComponent } from './modules/login/logout/logout.component';
import { AdminGuard } from './admin.guard';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
          path: 'dashboard/default',
          redirectTo: 'dashboard',
          pathMatch: 'full'
      },
      {
          path: '',
          loadChildren: './modules/dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'users',
        loadChildren: './modules/user/user.module#UserModule',
      },
      {
        path: 'news',
        loadChildren: './modules/news/news.module#NewsModule',
      },
      {
        path: '',
        loadChildren: './modules/maintenance/maintenance.module#MaintenanceModule'
      },
      {
        path: '',
        loadChildren: './modules/localisation/localisation.module#LocalisationModule',
        canActivate: [
          AdminGuard
        ]
      },
      {
        path: '',
        loadChildren: './modules/inventory/inventory.module#InventoryModule',
        canActivate: [
          AdminGuard
        ]
      },
    ]
  },
  {
    path: 'auth',
    component: AuthComponent,
    children: [
      {
        path: '',
        loadChildren: './modules/login/login.module#LoginModule'
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
