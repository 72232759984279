import { Injectable } from '@angular/core';
import { SecurityRepository } from '../repository/security.repository';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthManagement {
  private storage: Storage;

  constructor(private security: SecurityRepository) {
      this.storage = localStorage;
  }

  isLogged(): boolean {
    const token = this.getToken();
    if (!token) {
      return false;
    }

    if (token.until * 1000 > Date.now()) {
      return true;
    }

    return true;
  }

  login(user: LoginDomain): Observable<Token> {
    return new Observable<Token>((obs) => {
      const observer = this.security.login(user);
      observer.subscribe((token: Token) => {
        this.saveToken(token);
        obs.next(token);
      });
    });
  }

  getUser(): User {
    const token = this.getToken();
    if (!token) {
      return;
    }

    return token.user;
  }

  isLoggedAsAdmin() : boolean {
    return this.isLogged() && this.getUser().role == '2';
  }
  logout() {
    this.storage.removeItem('token');
  }

  getToken(): Token {
    let token = JSON.parse(this.storage.getItem('token'));

    return token;
  }

  saveToken(token: Token) {
    this.storage.setItem('token', JSON.stringify(token));
  }
}

export interface LoginDomain {
  email: string;
  plainPassword: string;
}

export interface User {
  id: string;
  email: string;
  firstname: string;
  lastname: string;
  society: string;
  role: string;
}

export interface Token {
  id: string;
  token: string;
  until: number;
  created: string;
  user?: User
}
