import {Injectable} from '@angular/core';
import { AuthManagement } from 'src/app/security/auth-management';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const MENUITEMS = [
  {
    label: 'Gestion',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'Dashboard',
        type: 'link',
        icon: 'feather icon-activity',
    },
    {
      state: 'tickets',
      short_label: 'B',
      name: 'Liste des interventions',
      type: 'link',
      icon: 'feather icon-briefcase',
    }
    ]
  },
  {
    label: 'Contextualisation',
    main: [
        {
            main_state: 'context',
            state: 'create-context',
            short_label: 'Edit',
            name: 'Règles d\'affectation',
            type: 'link',
            icon: 'feather icon-book',
        },
        {
          main_state: 'context',
          state: 'list',
          short_label: 'Edit',
          name: 'Règles d\'escalade',
          type: 'link',
          icon: 'feather icon-list',
      }
    ]
  },
  {
    label: 'Configuration',
    main: [
      {
        state: 'users',
        short_label: 'U',
        name: 'Gestion des utilisateurs',
        type: 'link',
        icon: 'feather icon-users'
      },
      {
        state: 'rooms',
        short_label: 'U',
        name: 'Gestion des espaces',
        type: 'link',
        icon: 'feather icon-codepen'
      },
      {
        main_state: 'room-types',
        state: 'list-types',
        short_label: 'SC',
        name: 'Types d\'espace',
        type: 'link',
        icon: 'feather icon-server',
      },
      {
        main_state: 'equipments',
        state: 'list-equipments',
        short_label: 'SL',
        name: 'Gestion des équipements',
        icon: 'feather icon-server',
        type: 'link',
      },
      {
        main_state: 'tasks',
        state: 'list-tasks',
        short_label: 'SL',
        name: 'Gestion des vérifications',
        icon: 'feather icon-server',
        type: 'link',
      }
    ]
  },
  {
    label: 'Actualité',
    main: [
      {
        main_state: 'news',
        state: 'list-news',
        short_label: 'SL',
        name: 'Gestion des actualités',
        icon: 'feather feather-plus-square',
        type: 'link',
      }
    ]
  }
];

const USERITEMS = [

{
  label: 'Gestion',
  main: [
    {
      state: 'dashboard',
      short_label: 'D',
      name: 'Dashboard',
      type: 'link',
      icon: 'feather icon-activity',
    },
    {
      state: 'tickets',
      short_label: 'B',
      name: 'Liste des interventions',
      type: 'link',
      icon: 'feather icon-briefcase',
    }
  ]
}
];

@Injectable()
export class MenuItems {
  constructor(private authManager :AuthManagement){

  }
  getAll(): Menu[] {
    if(this.authManager.isLoggedAsAdmin()){
      return MENUITEMS;
    } else {
      return USERITEMS;
    }
    
  }
}
